import React from "react"
import NuvoImage from "../components/NuvoImage"
import Layout from "../components/layout"
import SEO from "../components/seo"

import TheaterVideo from "@components/Theater/TheaterVideo"
import Button from "@bit/azheng.joshua-tree.button"
import CloseIcon from "../components/CloseIcon"
import GetStarted from "../components/CTA/GetStarted"

import { Dialog } from "@blueprintjs/core"

function createHtml(html) {
  return { __html: html }
}
var showdown = require("showdown")
const converter = new showdown.Converter()

import { Link, graphql } from "gatsby"
import MarkdownViewer from "@bit/azheng.joshua-tree.markdown-viewer"

var classNames = require("classnames")

class AnesthesiaOptionsPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialogIsOpen: false,
      componentShownInDialog: {},
    }
    this.toggleDialog = this.toggleDialog.bind(this)
  }

  componentDidUpdate(prevState) {
    if (
      this.state.dialogIsOpen !== prevState.dialogIsOpen &&
      this.state.dialogIsOpen
    ) {
      document.documentElement.style.overflowY = "hidden"
    } else {
      document.documentElement.style.overflowY = "scroll"
    }
  }

  toggleDialog(component) {
    this.setState((prevState) => ({
      dialogIsOpen: !prevState.dialogIsOpen,
      componentShownInDialog: component,
    }))
  }

  render() {
    const post = this.props.data.allUniquePagesJson.nodes[0]

    return (
      <Layout pageTitle="an-options">
        <SEO
          title={post.metaTitle}
          description={post.metaDescription}
          pathname={this.props.location.pathname}
          schemaPage={post.schemaPage ? post.schemaPage : null}
        />

        <Dialog
          canOutsideClickClose={true}
          enforceFocus={true}
          autoFocus={true}
          canEscapeKeyClose={true}
          isOpen={this.state.dialogIsOpen}
          usePortal={true}
          onClose={this.toggleDialog}
          className="anesthesia-dialog"
        >
          {this.state.componentShownInDialog}
        </Dialog>
        <div>
          <Button buttonText="BACK" goBack />

          <div className="procedure procedure-video-top-wrapper">
            <NuvoImage
              useAR
              cloudName="nuvolum"
              publicId={post.imageId}
              width="auto"
              className="procedure-hero-img"
            ></NuvoImage>

            <div className="columns procedure-heading-container">
              <div className="column is-2"></div>
              <div className="column anesthesia-heading-video flex-row-reverse">
                {post.youtube && (
                  <div className="procedures-theater-button">
                    <div>
                      <TheaterVideo
                        videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
                        controls={true}
                        playing={true}
                        overVideo={false}
                        buttonClass="contained"
                      ></TheaterVideo>
                    </div>
                  </div>
                )}

                <h1>{post.heading}</h1>
              </div>
              <div className="column is-2"></div>
            </div>

            <div className="columns">
              <div className="column is-5"></div>
              <div className="column">
                <div
                  className="anesthesia-top-blurb"
                  dangerouslySetInnerHTML={createHtml(
                    converter.makeHtml(post.topBlurbs)
                  )}
                ></div>
              </div>
              <div className="column is-5"></div>
            </div>
          </div>
        </div>

        <div
          className="columns has-text-centered color-back anesthesia-modal"
          style={{ paddingTop: "32px", paddingBottom: "50px" }}
        >
          <div className="column is-2"></div>
          <div className="column">
            <h3>{post.anesthesiaTypes.heading}</h3>
            <MarkdownViewer markdown={post.anesthesiaTypes.noInlinkBlurb} />
            <div className="button-group-wrapper">
              <div className="button-group is-centered has-4-button anesthesia-modal-buttons">
                {post.anesthesiaTypes.buttons.map((modal) => (
                  <React.Fragment key={modal.heading}>
                    {modal.href ? (
                      <Link to={modal.href}>
                        <a className="standard-button contained">
                          {modal.buttonText === "EXPAREL®" ? (
                            <>
                              EXPAREL<sup>®</sup>
                            </>
                          ) : (
                            modal.buttonText
                          )}
                        </a>
                      </Link>
                    ) : (
                      <a
                        className="standard-button contained"
                        onClick={() =>
                          this.toggleDialog(
                            <AnesthesiaModal
                              modalHeading={modal.heading}
                              modalBlurb={modal.blurb}
                              youtube={modal.youtube}
                              toggleDialog={this.toggleDialog}
                            />
                          )
                        }
                      >
                        {modal.buttonText}
                      </a>
                    )}
                  </React.Fragment>
                ))}
              </div>
            </div>
          </div>
          <div className="column is-2"></div>
        </div>

        {/* {post.youtube && post.imageId && <div
            className="body-sections anesthesia-watch-video"
          >
            <TheaterVideo
              videoUrl={`https://www.youtube.com/watch?v=${post.youtube}`}
              controls={true}
              playing={true}
              overVideo={true}
              buttonClass="contained"
            >
              <ImageMeta
                cloudName="nuvolum"
                publicId={post.imageId}
                
                width="auto"
              >
                
              </ImageMeta>
            </TheaterVideo>
          </div>} */}

        <div className="body-sections anesthesia-body-section">
          <div className="columns">
            <div className="column is-5"></div>
            <div
              className="column"
              dangerouslySetInnerHTML={createHtml(
                converter.makeHtml(post.body)
              )}
            ></div>
            <div className="column is-5"></div>
          </div>
        </div>

        <GetStarted
          className="get-started-cta has-text-centered anesthesia-options-cta color-back"
          centerHeading
          headingSideColumnIs={5}
          sideColumnIs={4}
          subheading={post.getStarted.subheading}
          heading={post.getStarted.heading}
          paragraph={post.getStarted.blurb}
          buttonText={post.getStarted.buttonText}
          buttonUrl={post.getStarted.href}
        />
      </Layout>
    )
  }
}

function AnesthesiaModal(props) {
  var modalClass = classNames({
    "c-menu": true,
    "dialog-one": true,
  })

  return (
    <div className="has-text-centered dialog-content">
      <CloseIcon onClick={props.toggleDialog} />
      <h3>{props.modalHeading}</h3>
      <p className="large">{props.modalBlurb}</p>
      {props.youtube && (
        <TheaterVideo
          videoUrl={`https://www.youtube.com/watch?v=${props.youtube}`}
          controls={true}
          playing={true}
          onlyButton={true}
          buttonClass="contained"
        />
      )}
      {!props.youtube && <div style={{ height: "80px" }}></div>}
    </div>
  )
}

export const pageQuery = graphql`
  query anesthesiaOptionsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        schemaPage
        anesthesiaTypes {
          buttons {
            buttonText
            heading
            blurb
            youtube
            href
          }
          blurb
          noInlinkBlurb
          heading
        }
        youtube
        imageId
        body
        getStarted {
          blurb
          buttonText
          heading
          href
          subheading
        }
        heading
        title
        topBlurbs
      }
    }
  }
`

export default AnesthesiaOptionsPage
